
import { defineComponent } from 'vue'
import { Linktree } from '@/data/Release'
import { linktree, streamYoutube, streamSpotify, streamApple } from '@/services/EventLogger'

export default defineComponent({
  props: {
    linktree: {
      type: Linktree,
      required: true
    }
  },

  setup() {
    linktree()
    return { streamYoutube, streamSpotify, streamApple }
  }
})
