<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 col-md-6 col-lg-5 col-xl-4">
        <div v-if="release.release">
          <!-- RELEASE -->
          <img class="release-image" :src="release.release.coverpath"/>
          <!-- <p>{{release.release.title}}</p> -->
          <linktree v-if="release.release.linktree" :linktree="release.release.linktree" id="linktree" />
        </div>

        <div v-else>
          <!-- NOT FOUND -->
          <p>Release nicht gefunden :(</p>
        </div>
      </div>
    </div>
  </div>
  
  <a id="back-link" href="Home">Alle Songs zeigen</a>
</template>

<script lang="ts">
class FilteredRelease {
  release: Release | undefined
}

import { useRoute } from 'vue-router'
import { defineComponent, reactive } from 'vue'
import Linktree from '@/views/components/LinkTree.vue'
import { Release, allReleases } from '@/data/Release'

export default defineComponent({
  components: {
    Linktree
  },

  setup() {
    const release = reactive(new FilteredRelease())

    const releaseID = useRoute().params.id
    const foundRelease = allReleases.filter((release: Release) => { return release.id === releaseID }).pop()
    release.release = foundRelease

    return { release }
  },

  watch: {
    '$route.params.id': {
      handler: function(releaseID: string | undefined) {
        if (!releaseID) { return }
        
        const foundRelease = allReleases.filter((release: Release) => { return release.id === releaseID }).pop()
        this.release.release = foundRelease
      }
    }
  }

})
</script>

<style lang="sass" scoped>
#linktree
  margin-top: 12px
  
#back-link
  margin-left: auto
  margin-right: auto
  display: table
  margin-top: 24px
  font-weight: 500
  font-size: 0.9em
  background: $background_light
  color: $foreground
  padding: 12px 24px 12px 24px
  border-radius: 12px
  text-decoration: none
  opacity: 0.8
  transition: 0.2s
  &:hover
    opacity: 1

.release-image
  border-radius: 18px
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.7)
  width: 100%
</style>
