
import { defineComponent } from 'vue'
import { allReleases } from '@/data/Release'
import ReleasePreview from '@/views/components/ReleasePreview.vue'
import { landingpage, profileYoutube, profileSpotify, profileApple, instagram, tiktok, twitter } from '@/services/EventLogger'

export default defineComponent({
  name: 'Home',

  setup() {
    landingpage()
    return { allReleases, profileYoutube, profileSpotify, profileApple, instagram, tiktok, twitter }
  },

  components: {
    ReleasePreview
  }
})
