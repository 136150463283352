<template>
  <div class="container-fluid">

    <div id="content-grid" class="row justify-content-center">

      <div id="subheader" class="col-12">
        <p>Producer - Songwriter - Engineer</p>
      </div>

      <div class="w-100"></div>

      <!-- STREAMING SERVICES -->
      <div class="socials-wrapper col-10 col-md-6 col-lg-5 col-xl-4">
        <div id="socials" class="row justify-content-center">
          <a class="social-link col-4" href="https://www.youtube.com/c/UNREALMIZO" target="_blank" @click="profileYoutube()">
            <img class="social-image" src="@/assets/icons/youtube.png" />
          </a>

          <a class="social-link col-4" href="https://music.apple.com/us/artist/mizo/1516020180" target="_blank" @click="profileApple()">
            <img class="social-image" src="@/assets/icons/applemusic.png" />
          </a>

          <a class="social-link col-4" href="https://open.spotify.com/artist/6JqX68BZRAnLPS9OtYiPsv?si=rc3apVHHRz-CoE9E_NrxBA" target="_blank" @click="profileSpotify()">
            <img class="social-image" src="@/assets/icons/spotify.png" />
          </a>
        </div>
      </div>

      <div class="w-100"></div>

      <!-- LATEST RELEASE -->
      <release-preview v-for="release in allReleases.slice(0, 1)" :key="release.id" :release="release" />

      <div class="w-100"></div>

      <!-- SOCIALS -->
      <div class="socials-wrapper col-10 col-md-6 col-lg-5 col-xl-4" style="margin-top: 12px">
        <div id="socials" class="row justify-content-center">
          <a class="social-link col-4" href="https://www.instagram.com/unrealmizo" target="_blank" @click="instagram()">
            <img class="social-image" src="@/assets/icons/instagram.png" />
          </a>

          <a class="social-link col-4" href="https://www.tiktok.com/@unrealmizo" target="_blank" @click="tiktok()">
            <img class="social-image" src="@/assets/icons/tiktok.png" />
          </a>

          <a class="social-link col-4" href="https://twitter.com/unrealmizo" target="_blank" @click="twitter()">
            <img class="social-image" src="@/assets/icons/twitter.png" />
          </a>
        </div>
      </div>

      <div class="w-100"></div>
      <release-preview v-for="release in allReleases.slice(1, allReleases.length)" :key="release.id" :release="release" />
      <div class="w-100"></div>
      
      <p id="signature">made by mizo</p>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { allReleases } from '@/data/Release'
import ReleasePreview from '@/views/components/ReleasePreview.vue'
import { landingpage, profileYoutube, profileSpotify, profileApple, instagram, tiktok, twitter } from '@/services/EventLogger'

export default defineComponent({
  name: 'Home',

  setup() {
    landingpage()
    return { allReleases, profileYoutube, profileSpotify, profileApple, instagram, tiktok, twitter }
  },

  components: {
    ReleasePreview
  }
})
</script>

<style scoped lang="sass">
#subheader
  color: $foreground_variant

#content-grid
  margin-bottom: 15%

.socials-wrapper
  padding: 0px 20px 0px 20px

#socials
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3)
  padding: 8px
  margin-bottom: 12px
  border-radius: 18px
  background: $background_light
  margin-left: auto
  margin-right: auto

.social-link
  padding: 0px 8px 0px 8px

.social-image
  width: 100%
  padding: 0
  transition: 0.2s
  opacity: 1.0
  &:hover
    padding: 3px
    opacity: 0.9

#signature
  font-style: italic
  margin-top: 50px
  color: $foreground_variant
</style>
