
import { defineComponent } from 'vue'
import { Release } from '@/data/Release'
import router from '@/router'
import { directlink } from '@/services/EventLogger'

export default defineComponent({
  props: {
    release: {
      type: Release,
      required: true
    }
  },

  setup() {
    return { }
  },

  methods: {
    openLinktree() {
      // only open linktree if no direct link is provided.
      if (this.release.linktree && !this.release.directlink) {
        router.push({ name: 'Release', params: { id: this.release.id } })
      } else if(this.release.directlink) {
        directlink()
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: this.release.directlink,
          }).click();
      }
    }
  }
})
