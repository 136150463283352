<template>
  <p id="header-text" @click="goHome">mizo</p>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import router from './router'

export default defineComponent({
  setup() {
    return { }
  },

  methods: {
    goHome() {
      router.push({ name: 'Home' })
    }
  }
})
</script>


<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap')

@import url('https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap')

$breakpoint-md: 768px

html
  background-color: $background

#app
  background-color: $background
  font-family: 'Roboto', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $foreground
  text-align: center
  padding: 0 0 0 0
  @media (min-width: $breakpoint-md)
    padding: 0 10vw 0 10vw

#header-text
  cursor: pointer
  margin: 20px 0px 10px 0px
  font-size: 3em
  // font-family: 'Nanum Pen Script', cursive
  font-family: 'Loved by the King', cursive
</style>
