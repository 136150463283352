export class Release {
  id: string
  title: string
  description: string
  coverpath: string
  directlink?: string
  presave: boolean
  linktree?: Linktree

  constructor(id: string, title: string, description: string, coverpath: string, presave: boolean, directlink?: string, linktree?: Linktree) {
    this.id = id
    this.title = title
    this.description = description
    this.coverpath = coverpath
    this.directlink = directlink
    this.presave = presave
    this.linktree = linktree
  }
}

export class Linktree {
  youtube?: string
  spotify?: string
  applemusic?: string

  constructor(youtube?: string, spotify?: string, applemusic?: string) {
    this.youtube = youtube
    this.spotify = spotify
    this.applemusic = applemusic
  }
}

const covers = require.context('@/assets/covers/', false)

export const allReleases: Release[] = [
  new Release('tieferschlaf', 'Tiefer Schlaf', 'Musikvideo', covers('./tieferschlaf.jpg'), false, 'https://www.youtube.com/watch?v=6l9IkhjQZEU'),
  new Release('donner', 'Donner', '2020', covers('./donner.jpg'), false, undefined, new Linktree(
    'https://www.youtube.com/watch?v=7y1rZNUQCeo', 
    'https://open.spotify.com/album/4KjH2e7EBlsIiuOvMlT5lC?si=IJnhQdrFTduxQgOKhuO2uQ', 
    'https://music.apple.com/us/album/donner-single/1494744049')
  )
]
