<template>
  <div class="release-wrapper col-10 col-md-5 col-lg-4 col-xl-3">
    <a
      class="release"
      @click="openLinktree"
    >
      <div class="release-image-wrapper">
        <div class="release-cover">
          <div class="cover-text" v-if="release.presave">
            <p>presave</p>
          </div>
          <div class="cover-text" v-else>
            <img src="@/assets/icons/play.svg" />
          </div>
          <img class="release-image" :src="release.coverpath"/>
        </div>
      </div>
      <p class="release-title">{{release.title}}</p>
      <p class="release-year">{{release.description}}</p>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Release } from '@/data/Release'
import router from '@/router'
import { directlink } from '@/services/EventLogger'

export default defineComponent({
  props: {
    release: {
      type: Release,
      required: true
    }
  },

  setup() {
    return { }
  },

  methods: {
    openLinktree() {
      // only open linktree if no direct link is provided.
      if (this.release.linktree && !this.release.directlink) {
        router.push({ name: 'Release', params: { id: this.release.id } })
      } else if(this.release.directlink) {
        directlink()
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: this.release.directlink,
          }).click();
      }
    }
  }
})
</script>

<style lang="sass" scoped>
.release-wrapper
  padding: 0

.release
  display: block
  text-decoration: none
  padding: 12px

.release-image-wrapper
  cursor: pointer
  transition: 0.3s
  width: 100%
  padding: 8px
  &:hover
    padding: 0px

.release-cover
  position: relative
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3)
  transition: 0.2s
  width: 100%
  border-radius: 18px
  overflow: hidden
  &:hover
    box-shadow: 0px 0px 25px -2px rgba(0, 0, 0, 0.9)

.release-image
  width: 100%
  
.cover-text
  text-align: center
  color: $foreground
  position: absolute
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)
  opacity: 0
  transition: 0.2s
  &:hover
    opacity: 1.0
  
.cover-text > p
  font-weight: 900
  font-size: 1.5em
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  margin: 0

.cover-text > img
  position: absolute
  width: 40%
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  margin: 0

.release-title
  color: $foreground
  font-weight: 700
  margin: 8px 0px 0px 0px

.release-year
  color: $foreground_variant
  margin: 0px
</style>
