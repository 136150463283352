import { firebaseApp } from './Firebase'
import 'firebase/analytics'

export function landingpage(): void {
  firebaseApp.analytics().logEvent('landingpage', {})
}

export function directlink(): void {
  firebaseApp.analytics().logEvent('directlink', {})
}

export function linktree(): void {
  firebaseApp.analytics().logEvent('linktree', {})
}

export function streamYoutube(): void {
  firebaseApp.analytics().logEvent('stream_youtube', {})
}

export function streamSpotify(): void {
  firebaseApp.analytics().logEvent('stream_spotify', {})
}

export function streamApple(): void {
  firebaseApp.analytics().logEvent('stream_apple', {})
}

export function profileYoutube(): void {
  firebaseApp.analytics().logEvent('profile_youtube', {})
}

export function profileSpotify(): void {
  firebaseApp.analytics().logEvent('profile_spotify', {})
}

export function profileApple(): void {
  firebaseApp.analytics().logEvent('profile_apple', {})
}

export function instagram(): void {
  firebaseApp.analytics().logEvent('instagram', {})
}

export function tiktok(): void {
  firebaseApp.analytics().logEvent('tiktok', {})
}

export function twitter(): void {
  firebaseApp.analytics().logEvent('twitter', {})
}
