import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAmmmfQCiy3gRSueJ5RslDd-t7dmbouijQ',
  authDomain: 'mizo-art.firebaseapp.com',
  projectId: 'mizo-art',
  storageBucket: 'mizo-art.appspot.com',
  messagingSenderId: '510666049046',
  appId: '1:510666049046:web:22ce8482333b5c76dcd737',
  measurementId: 'G-9TTKCKB5VM'
};

export const firebaseApp = firebase.initializeApp(firebaseConfig)
