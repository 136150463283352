<template>
  <div>
    <a v-if="linktree.youtube" class="treelink" :href="linktree.youtube" @click="streamYoutube()" target="_blank">
      <img src="@/assets/icons/youtube.png"/>
      <p>Auf YouTube ansehen</p>
    </a>
    <a v-if="linktree.spotify" class="treelink" :href="linktree.spotify" @click="streamSpotify()" target="_blank">
      <img src="@/assets/icons/spotify.png"/>
      <p>In Spotify öffnen</p>
    </a>
    <a v-if="linktree.applemusic" class="treelink" :href="linktree.applemusic" @click="streamApple()" target="_blank">
      <img src="@/assets/icons/applemusic.png"/>
      <p>In Apple Music öffnen</p>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Linktree } from '@/data/Release'
import { linktree, streamYoutube, streamSpotify, streamApple } from '@/services/EventLogger'

export default defineComponent({
  props: {
    linktree: {
      type: Linktree,
      required: true
    }
  },

  setup() {
    linktree()
    return { streamYoutube, streamSpotify, streamApple }
  }
})
</script>

<style lang="sass" scoped>
.treelink
  text-decoration: none
  color: $foreground
  display: block
  background: $background_light
  padding: 8px
  border-radius: 8px
  margin-bottom: 12px
  transition: 0.2s
  text-align: left
  &:hover
    background: $foreground_variant

.treelink > img
  width: 40px

.treelink > p
  margin-left: 8px
  display: inline
  font-weight: 500
  
    
</style>
