
class FilteredRelease {
  release: Release | undefined
}

import { useRoute } from 'vue-router'
import { defineComponent, reactive } from 'vue'
import Linktree from '@/views/components/LinkTree.vue'
import { Release, allReleases } from '@/data/Release'

export default defineComponent({
  components: {
    Linktree
  },

  setup() {
    const release = reactive(new FilteredRelease())

    const releaseID = useRoute().params.id
    const foundRelease = allReleases.filter((release: Release) => { return release.id === releaseID }).pop()
    release.release = foundRelease

    return { release }
  },

  watch: {
    '$route.params.id': {
      handler: function(releaseID: string | undefined) {
        if (!releaseID) { return }
        
        const foundRelease = allReleases.filter((release: Release) => { return release.id === releaseID }).pop()
        this.release.release = foundRelease
      }
    }
  }

})
