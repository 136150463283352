
import { defineComponent } from 'vue'
import router from './router'

export default defineComponent({
  setup() {
    return { }
  },

  methods: {
    goHome() {
      router.push({ name: 'Home' })
    }
  }
})
